.cocktail-filters {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .cocktail-filters h4 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  
  .cocktail-filters input[type="text"] {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .checkbox-group {
    margin-bottom: 20px;
    padding: 15px 25px;
    border: 1px solid #fed126ff;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #2f2f2f;
    transition: background-color 0.1s ease-out;
  }
  
  .checkbox-group h4 {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .category-icon {
    width: 20px; /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
    margin-right: 10px; /* Space between the icon and the text */
    vertical-align: middle; /* Aligns the icon with the text */
  }
  
  .checkbox-group label {
    display: block;
    margin-bottom: 10px;
    font-size: 0.9rem;
    cursor: pointer;
    display: flex;
  align-items: center;
  }
  
  .checkbox-group input[type="checkbox"] {
    margin-right: 10px;
    appearance: none; /* Remove default styling */
    width: 20px;
    height: 20px;
    border: 2px solid #ccc; /* Border for the checkbox */
    border-radius: 4px; /* Rounded corners */
    outline: none;
    cursor: pointer;
    position: relative;
  }
  
  /* Change the checkbox background when checked */
  .checkbox-group input[type="checkbox"]:checked {
    background-color: #fed126ff;
    border-color: #fed126ff;
  }
  
  /* Style the hover effect */
  .checkbox-group input[type="checkbox"]:hover {
    border-color: #fed126ff;
  }
  
  .checkbox-group:hover {
    background-color: #272626;
  }
  
  .cocktail-filters input[type="text"]:focus {
    outline: none;
    border-color: #fed126ff;
    box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
  }
  