/* src/components/styling/orderDetails.css */

#detailsWrapper {
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  background-color: rgb(242, 243, 236);
  background-color: #0A0A0A;
  display: flex;
  justify-content: center;
}

.white{
  color:#F6F8FF;  
}

#detailsBox {
  margin: 0;
  padding: 100px 50px;
  max-width: 1200px;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #0A0A0A;
  color:#F6F8FF;  
  width: 100%;
  box-sizing: border-box;
  font-family: Garamond;
  display: flex;
  justify-content: space-between;
}

#detailsBox h3,
#detailsBox p{
  margin-left: 0;
}

.detailsColumn {
  width: 45%;
  background-color: #2F2F2F69;
  border-radius: 5px;
  padding: 25px;
  box-sizing: border-box;
  height: fit-content;
}

.detailsColumn h4{
  padding-top: 20px;
  border-top: 1px solid #fed126ff;
  margin: 0 0 10px;
  font-size: 1rem;
}

.detailsColumn ul{
  list-style: none;
  padding:0;
}

.nameWrapper{
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
}

.nameWrapper input:nth-of-type(1),
.nameWrapper input:nth-of-type(2){
  margin-right: 5px;
}

.btn-bewerk{
  border: none;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
  color: #F6F8FF;  
}

#detailsBox h2 {
  margin-bottom: 20px;
  border-bottom: 2px solid #fed126ff;
  padding-bottom: 10px;
}

form {
  display: flex;
  flex-direction: column;
}

form input, form select, form textarea {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 0;
  font-family: Garamond;
}

form button {
  background-color: rgb(255, 212, 41);
  color: rgb(47, 47, 47);
  border: none;
  cursor: pointer;
}

form button:hover {
  background-color: rgb(85, 116, 110);
  color: white;
}

form label{
  margin-bottom: 10px;
}

input[type="checkbox"]:focus,
input[type="radio"]:focus {
 outline:none;
}

.differentAddressDiv{
  display: flex;
  flex-direction: column;
  margin:20px 0;
}

#orderSummary, #payBox {
  margin-bottom: 20px;
}

.orderGrid{
  display: grid;
  grid-template-columns: 0.7fr 2fr;
}

#orderSummary button {
  background-color: rgb(255, 212, 41);
  color: rgb(47, 47, 47);
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

#orderSummary button:hover {
  background-color: rgb(85, 116, 110);
  color: white;
}

#payBox {
  background-color: #f8f9fa;
  color:#0A0A0A;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 50px 0;
}

#detailsBox h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid #fed126ff;
  padding-bottom: 10px;
}

.priceDetails p{
  justify-content: center;
}

textarea {
  height: 100px;
  resize: none;
  font-family: Garamond;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #f8f9fa;
}

#detailsBox input{
  background-color: #f8f9fa;
}

textarea:focus {
  border-color: transparent; /* Border color when the textarea is focused */
  outline: 2px solid rgb(85, 116, 110); /* Removes the default outline on focus */
  border-radius: 5px;
}

input:focus{
  border-color: transparent; /* Border color when the textarea is focused */
  outline: 2px solid rgb(85, 116, 110); /* Removes the default outline on focus */
  border-radius: 5px;
}

#detailsBox p {
  margin: 5px 0 15px;
  display: flex;
}
#detailsBox p img{
height: 15px;
margin: 0 5px;
}

.confirmWrapper{
  margin: 50px 0;
  border-top:1px solid #fed126ff; 
  padding: 20px 0;
}

.confirmWrapper p{
  text-align: justify;
}

.confirmWrapper .terms{
  display: inherit!important;
}

.confirmWrapper .terms a{
color: inherit;
}

.confirmWrapper input{
  margin-right: 10px;
}

.confirmWrapper button{
  margin-top:20px;
}

.confirmWrapper button:disabled  {
  background-color: #cccccc;
  color: #666666;
  cursor: default;
  opacity: 0.6;
}

#orderLoadingBox{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.confirmWrapper #orderStatusMessage{
  justify-content: center;
  margin-top: 5px;
  color:#e57a44ff;
}

.confirmWrapper .edit-order-btn{
  color: white;
  text-decoration: underline;
  justify-content: center;
}
