.cocktailBox{
    min-height: 100vh;
    background-color: #0A0A0A;
    font-family: merriweather;
    color: #F6F8FF;
    padding: 100px 100px 200px;
    box-sizing: border-box;
    text-align: justify;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.coctailTitleWrapper{
  text-align: justify;
}

.coctailTitleWrapper h1{
text-align: center;
margin: 0 0 100px;
}

.cocktailFilters{
    margin-bottom: 50px;
}