#adminBox{
    background-color: #f9f9fb;
    color:rgb(47, 47, 47);
    min-height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
}

.adminNavSpacer{
    height: 70px;
}

#adminSpacer{
    flex-basis: 20vw;
  min-width: 300px;
  max-width: 400px;
  position: relative;
}

#adminInnerWrapper{
    background-color: #ffffff;
    flex: 2;
    min-height: 100vh;
    border-radius: 10px;
    margin:10px 20px 10px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;    
    padding: 50px;
    -webkit-box-shadow: 0px 0px 18px -10px rgba(179,179,179,1);
-moz-box-shadow: 0px 0px 18px -10px rgba(179,179,179,1);
box-shadow: 0px 0px 18px -10px rgba(179,179,179,1);
z-index: 15;
font-family: nunito;
}

.lightBorder{
    padding: 30px;
    border: 1px solid #e6e1e1;
    border-radius: 20px;
    box-shadow: 0px 0px 18px -10px rgb(230, 230, 230);
    -moz-box-shadow: 0px 0px 18px -10px rgb(230, 230, 230);
box-shadow: 0px 0px 18px -10px rgb(230, 230, 230);
}

.adminHeader{
  border-bottom: 2px solid rgb(85, 116, 110);
  padding-bottom: 10px;
  width: 100%;
  margin: 25px 0;
}


#adminHomeBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: nunito;
}

#adminHomeBox img{
    height: 100px;
}

#adminHomeBox h2{
    margin: 50px 0 0;
    font-size: 1.5rem;
    font-weight: 600;
  padding-bottom: 25px;
  width: 100%;
  text-align: center;
}

 


@media (max-width: 500px) {

    .mobile-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgb(85, 116, 110);
        color: white;
        padding: 10px 15px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 50px;
        z-index: 150;
        height: 70px;
        box-sizing: border-box;         
        font-family: nunito;
      }

      .mobile-header span{
        height: 100%;
        display: flex;
        align-items: center;
      }

      .mobile-header a{
        color:white;
        text-decoration: none;
        cursor: pointer;
      }

      .mobile-header button i{
        font-size: 35px;
      }

      .mobile-header i{
        margin-right: 10px;
      }
      
      .menu-toggle {
        background: none;
        border: none;
        color: white;
        font-size: 24px;
        cursor: pointer;
      }
      
      .sidebar-closed {
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        position: fixed;
        top: 50px;
        left: 0;
        height: 100vh;
        width: 250px;
        background-color: #222;
        z-index: 101;
      }
      
      .sidebar-open {
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;
      }

      #adminSpacer{
        width: 0!important;
        height: 0!important;
        position: absolute;
        overflow: hidden;
        visibility: hidden;
      }

      .overlay-active {
        position: relative;
      }
      
      .overlay-active::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.4); /* Dark overlay */
        z-index: 100; /* Ensure it's above content */
      }

      #adminSideBarBox{
        z-index: 100!important;
        height: 100%;
      }
      
      #adminInnerWrapper {
        flex-grow: 1;
        padding: 15px;
        margin: 25px 2.5vw!important;
        box-sizing: border-box;
        width: calc(100% - 5vw);
      }

      #adminInnerWrapper h2{
        font-size: 20px;
      }

      .lightBorder{
        padding: 5vw!important;
        margin-bottom: 25px;
      }
   


}