/* src/components/styling/login.css */

.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: start;
  min-height: 100vh;
  background-color: #f9f9fb;
  padding: 100px 5vw;  
font-family: nunito;
  }
  
  .login-box {
    background-color: white;
    color: rgb(47, 47, 47);
    padding: 40px;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 18px -10px rgba(179,179,179,1);
-moz-box-shadow: 0px 0px 18px -10px rgba(179,179,179,1);
box-shadow: 0px 0px 18px -10px rgba(179,179,179,1);
    max-width: 500px;
    width: 100%;
    text-align: center;
  }

  .login-box img{
    height: 40px;
    margin: 0 auto 25px;
  }
  
  .login-box h2 {
    margin:0;
  }
  
  .login-box form {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
  }
  
  .login-box input {
    font-family: nunito!important;
    padding: 10px 15px;
    box-sizing: border-box;
    background-color: #e9e8ee;
    border:none;
    width: 100%;
    box-sizing: border-box;
    transition: 0.15s ease-out;

  }

  .reset-box input{
    margin-bottom: 0!important;
  }

  .login-box input:hover{
    background-color: #dddbe5;
  }
  
  .login-box .btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 10px;
    font-family: nunito;
    height: inherit!important;
  }
  
  
  .login-box a {
    text-decoration: underline;
    color:inherit!important; 
  }

  .login-box a p{
    margin-top: 25px;
  }
  

  .reset-box .input-group {
    position: relative;
    margin-bottom: 10px;
  }
  
  .reset-box .input-group-text {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
  }

  .login-box h4{
    margin-top: 50px;
  }

  .login-box ul{
    padding-left: 0;
    list-style: none;
  }
  


  @media (max-width: 500px) {

    .login-wrapper{
      box-sizing: border-box;
      align-items: start;
    }
    .login-box{
      padding:40px 20px 20px;
    }



  }