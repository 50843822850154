/* src/components/styling/analytics.css */

.analytics-container {
    width: 100%;
    box-sizing: border-box;
  }
  
  .analytics-container h1 {
    margin-bottom: 20px;
    margin-top: 0;
    border-bottom: 2px solid rgb(85, 116, 110);
    padding-bottom: 10px;
  }
  

  .analyticsBox{
    border-bottom: 2px solid rgb(85, 116, 110);
    padding: 50px 0;
    display: flex;
    justify-content: center;
  }

  .analyticsBox:last-of-type{
    border-bottom:0!important;
    padding-bottom: 0!important;
  }

  .order-analytics{
    width: 50%;
    max-width: 500px;
    min-width: 300px;
  }

  .order-analytics h2{
    text-align: center;
  }

  .order-analytics .filter-container{
    display: flex;
    justify-content: center;
  align-items: center;
  }

  .order-analytics .lightBorder{
    padding: 10px 20px;
    margin: auto;
  }

  .order-analytics .filter-container select{
    margin-left: 10px;
    background-color: #e9e8ee;
    font-family: nunito;
    padding: 5px 10px;
  }