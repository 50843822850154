/* src/components/styling/adminOrders.css */
#adminOrdersWrapper {
  width: 100%;
  min-height: 100vh;  
  display: flex;
  justify-content: center;
}

.container {
  min-height: 100vh;
  background: white;
  border-radius: 8px;
  color: rgb(47, 47, 47);
  width: 100%;
  box-sizing: border-box;
}


.filters {
  display: flex;
  justify-content: start;
  margin: 50px 0 25px;
  align-items: center;
  flex-direction: column;
}

.filters .adminHeader{
  margin-top: 0;
}

.filters div:first-of-type{
  display: flex;
  width: 100%;
}

.filters .filterWrapper{
  display: flex;
  width: fit-content;
  padding: 10px 20px!important;
  margin-right: 25px;
}

.filters .filterWrapper:last-of-type{
  margin-right: 0!important;
}


.filters select{
  margin-left: 10px;  
  background-color: #e9e8ee;
  font-family: nunito;
  padding: 5px 10px;
}

.filters select option:hover{
  background-color: #adadad;
}

.filters label {
  display: flex;
  align-items: center;
}

#orderSearchBar{
  position: relative;
  display: flex;
  width: fit-content;
  margin-right: 50px;
}

#orderSearchBar i {
  position: absolute;
  left: 20px; 
  top: 50%;
  transform: translateY(-50%);
  color: rgb(85, 116, 110);
  font-size: 16px;
}

#orderSearchBar input{
  border-radius: 50px;
  padding: 15px 0 15px 45px;
  box-sizing: border-box;
  background-color: #e9e8ee;
  border:none;
  width: 100%;
  flex-grow: 1;
  max-width: 500px;
  min-width: 300px;
    font-family: nunito;    
    transition: 0.15s ease-out;
}

#orderSearchBar input:hover{
  background-color: #dddbe5;
}


table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

thead {
  border-bottom: 1px solid rgb(85, 116, 110);
  cursor: pointer;
}

thead th {
  padding: 10px;
  text-align: left;
}

tbody td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

table button{
  margin-right: 5px;
}


.action-link {
  color: #007BFF;
  text-decoration: none;
}

.action-link:hover {
  text-decoration: underline;
}



/* Adjust container layout and padding for mobile */
@media (max-width: 1200px) {


  .container h2 {
    margin: 20px 0 35px;
  }

  /* Stack filters vertically and adjust padding */
  .filters {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin:25px 0;
  }

  .filters .filterWrapper{
    margin: 15px 0 0 0!important;
    width: 100%;
    box-sizing: border-box;
  }

  .filters label{
    width: 100%;
    justify-content: space-between;
  }

  .filters input {
    box-sizing: border-box;
    min-width: auto!important;
  }

  .filters div:first-of-type{
    flex-direction: column;
    overflow: hidden;
  }

  #orderSearchBar{
    width: 100%;
    margin: 0!important;
  }

  #orderSearchBar input{
    box-sizing: border-box;
    min-width: auto!important;
  }

  #orderSearchBar .lightBorder{
    width: 100%;
  }

  #orderSearchBar .lightBorder select{
    width: auto!important;
  }

  /* Adjust table layout for smaller screens */
  table {
    font-size: 0.9em;
    overflow-x: auto;
    display: block;
  }

  thead {
    display: none; /* Hide table headers for a simpler layout */
  }

  tbody {
    display: block;
    width: 100%;
  }

  tbody tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
  }

  tbody td {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    width: 100%;
  }

  tbody td:last-of-type {
    justify-content: center;
    border-bottom: none;
  }

  /* Adjust button size for mobile */
  table button,
  .btn-download,
  .btn-pulci-green {
    padding: 8px 12px;
    font-size: 0.9em;
  }

  .product-option-buttons{
  display: flex;
  justify-content: space-evenly!important;
  }

  .product-option-buttons button{
    width: fit-content;
  }

  td .btn-download{
    padding: 5px 10px!important;
    width: fit-content;
  }
}

