.back{
    height: 25px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 15px;    
    cursor: pointer;
    margin-left: 5vw;
}

.back p{
    margin: 0;
    color:#fed126ff;
    transition: 0.1s ease-out;
}

.back p:hover{
    color:rgb(196, 163, 32);
}

.back img{
    margin-top: 1px;
  margin-right: 5px;

}


.product-page {
    max-width: 1200px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 100px 50px 140px;
    font-family: merriweather;
    background-color: #0A0A0A;
    color:#F6F8FF;  
    display: flex;
    align-items: center;
    justify-content: center;
  }
     
  .product-page .product-image{
    max-width: 50%;
  padding: 50px;
  box-sizing: border-box;
  background-color: #2F2F2F69;
  -webkit-backdrop-filter: blur(7.5px);
  backdrop-filter: blur(7.5px);
  border-radius: 5px;
  margin-right: 100px;
  height: 700px;
}

.product-page .product-image .single-image{
  height: 100%;
  width: auto;
}

.slick-slide img{
  height: 500px;
}

.product-page .slider-for{
    height: 500px;
}

.product-page .slider-for img{
    height: 500px!important;
    width: auto!important;
}

.product-page .slick-slide{
    background-color: #2F2F2F69;
  overflow: hidden;
}

.product-page .slider-for .slick-slide{
    border-radius: 5px;
}

.product-page .slider-for .slick-slide div div{
    display: flex!important;
    justify-content: center!important;
}

.product-page .slider-nav{
    margin-top: 25px;
}

.slider-nav-pellegrino .slick-list{
  height: 100px;
  margin-bottom: 25px;
}

.product-page .slider-nav .slick-slide{
  width: 150px!important;
  height: 100px;
}

.product-page .slider-nav .slick-slide div {
    height: 100%;
}

.product-page .slider-nav .slick-slide div div{
   display: flex!important;
   justify-content: center;
   align-items: center;
   height: 100%;
  }


.product-page .slider-nav img{    
    width: 100%!important;
    height: auto!important;
}


.product-content img{
    height: 50px;
}
  .product-content h1 {
    font-size: 2rem;
    color: #fed126ff;
  }
  
  .product-content h2 {
    font-size: 1.8rem;
    margin: 0 0 10px 0;
  }
  
  .description {
    font-size: 1.1rem;
    font-style: italic;
    color: #666;
  }
  
  .product-description p {
    line-height: 1.6;
    margin: 15px 0;
  }
  
  .product-description ul {
    margin: 15px 0;
    padding-left: 0;
  }
  
  .product-description ul li {
    list-style-type: none;
    margin-bottom: 5px;
  }

  .product-description ul li strong {
    color:#fed126;

  }

  .perfectVoor li{
    list-style-type: disc!important;
    margin-left: 20px;
  }

  .product-details{
    display: flex;
    height: fit-content;
    margin:25px 0;
  }

  .product-details div{
    display: flex;
  }

  .product-details div:first-of-type{
    margin-right: 15px;    
    align-items: center;
  }

  .product-details div:last-of-type{
    flex-direction: column;
    justify-content: center;
  }

  .product-details img{
    height: 75px;
    width: auto;
  }
  
  .product-details p {
    margin: 5px 0;
    line-height: 1.2;
  }
  
  .order-section {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  
  .order-section input[type="number"] {
    width: 60px;
    margin-left: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
  }
  
  .add-to-cart {
    margin-left: 20px;
    padding: 10px 20px;
    background-color: #fed126ff;
    color: rgb(47, 47, 47);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.1s ease-out;
    font-family: merriweather;
  }
  
  .add-to-cart:hover {
    background-color: rgb(194, 165, 50);
  }

  .cart-message{
    text-align: center;
    margin-top: 10px;
  }
  
  .availability {
    font-size: 0.9rem;
    margin-top: 25px;
    color: #555;
    display: flex;
  justify-content: center;
  }

  .nixWrapper{
    width: 100%;
    margin: 5px 0 25px;
    display: flex;
    justify-content: center;
  }

  .nixWrapper img{
    height: 25px;
  }
  
