/* Responsive adjustments */

#adminShippingWrapper{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  justify-content: start;
}

#adminShippingWrapper .shippingSection{
    margin-top: 25px;
}

#adminShippingWrapper h3{
    display: flex;
    align-items: center; 
    justify-content: space-between;
    cursor: pointer;
}

#adminShippingWrapper h3 span{
    flex: 1;
}

#adminShippingWrapper h3 i{
    font-size: 1.2rem;
    margin-right: 10px;
}

@media (max-width: 1200px) {

    #adminShippingWrapper tr td:first-of-type{
        margin-right: 25px;
    }

    #adminShippingWrapper .container{
        margin: 0;
        width: 100%;
    }

}
