.cocktailGridWrapper{
    width: 100%;
}

.cocktail-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    background-color: #0A0A0A;
    justify-items: center;
    width: 100%;
  }
  
  .cocktail-grid-title {
    color: #fed126ff;
    text-align: center;
    margin-bottom: 30px;
    font-size: 2.5rem;
    font-family: "EB Garamond", serif;
  }
  