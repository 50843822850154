.cocktail-card {
    perspective: 1000px;
    width: 250px;
    height: 350px;
    margin: 15px;
    cursor: pointer;
    position: relative; /* Ensure it remains aligned in its grid/parent */
  }
  
  .card-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s ease-in-out;
  }
  
  .cocktail-card.flipped .card-inner {
    transform: rotateY(180deg);
  }
  
  .card-front,
  .card-back {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #2F2F2F69;
    color: #F6F8FF;
    font-family: "EB Garamond", serif;
    padding: 15px;
    text-align: center;
    box-sizing: border-box; /* Ensures padding doesn't affect card size */
  }
  
  .card-front img {
    border-radius: 5px;
    width: 90%; /* Scales image to fit within the card */
    height: auto;
    margin-bottom: 15px;
  }
  
  .card-front h3,
.card-back h3 {
  font-size: 1.2rem;
  color: #fed126ff;
  margin: 0;
  text-align: center;
  height: 3.5rem; /* Fixed height to accommodate up to two lines */
  display: flex; /* Use flexbox for vertical alignment */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  overflow: hidden; /* Prevent overflow */
  text-overflow: ellipsis; /* Add "..." if necessary */
  line-height: 1.75rem; /* Adjust line height for readability */
  white-space: normal; /* Allow wrapping to the next line */
}
  
  .card-back {
    transform: rotateY(180deg);
    padding: 20px; /* Ensure content on the back is spaced nicely */
    transition: background-color 0.5s ease-in;
  }

  .cocktail-card.flipped .card-back{
    background-color: #2f2f2f;
  }
  
  .card-back ul {
    list-style: none;
    padding: 0;
    margin: 15px 0;
    text-align: left;
  }
  
  .card-back ul li {
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
  
  .card-back p {
    font-size: 0.9rem;
    margin-top: 10px;
  }
  
  .card-inner {
    transform-origin: center; /* Ensures the flip happens from the center */
  }
  