/* src/components/styling/confirmModal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 95vw;
    text-align: center;    
font-family: nunito;
  }
  
  .modal button{
    margin: 0!important;    
    font-family: nunito;
  }

  .modal button:first-of-type{  
    background-color: rgb(85, 116, 110)!important;
    color:white;
  }
  .modal button:first-of-type:hover{
    background-color: rgb(74, 96, 92);
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }

  .modal input {
    font-family: nunito !important;
    padding: 10px;
    box-sizing: border-box;
    background-color: #e9e8ee;
    border: none;
    width: 100%;
    box-sizing: border-box;
    transition: 0.15s ease-out;
  }
  .modal input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    min-width: 0;
    font-family: Garamond;
  }
  
  .btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
    
  .btn.cancel {
    background-color: #f44336;
    color: white;
  }
  