.containerProducts{
  width: 100%;
  min-height: 100vh;
}

.btn-add-product{
    background-color: rgb(85, 116, 110);
    color:white;
    padding:8px 15px!important;
    border:none;
    border-radius: 5px;
    font-family: nunito;
    height: 50px;    
    transition: 0.15s ease-out;
}

.btn-add-product:hover{
  background-color: rgb(74, 96, 92);
}

.btn-add-product i{
  margin-right: 10px;
}

.btn-product-list{
    padding: 5px 10px;
}


.btn-save-product{
    margin-top: 50px;
    padding: 10px 25px;
    transition: 0.15s ease-out;
    border-radius: 5px;
    font-family: nunito;
    background-color: rgb(85, 116, 110);
    color: white;
}

.btn-save-product:hover{
  background-color: rgb(62, 83, 79);
}

.addUserBox .btn-save-product{
  margin-top: 0px;
}

.addUserBox .lightBorder{
  margin-top: 50px;
}

.labelTip{
    margin: 0 0 10px;
}

.productSelectWrapper{
    margin-top: 50px;
}

.previewTitle{
    margin: 50px 0 25px;
    padding-bottom: 25px;
    justify-content: center;
    display: flex;
}


.admin-controls {
    margin: 50px 0 25px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: start;  
    height:fit-content;     
    align-items: stretch; 
  }

  .admin-controls .lightBorder{
    height: 100%;
    box-sizing: border-box;
  }

  .admin-controls .filterBoxWrapper{
    flex-grow: 1;
  }

  .admin-controls .adminHeader{
    margin-top: 0;
  }
  
  .control-group {
    display: flex;
    gap: 10px;
    flex-grow: 1;
  }
  
  .search-input {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    flex-grow: 1;
    max-width: 300px;
  }
  
  .control-group select{
    margin-left: 10px;
  background-color: #e9e8ee;
  font-family: nunito;
  padding: 5px 10px;
  }

  .control-group .filterWrapper {
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 20px !important;
    margin-right: 25px;
  }
  
  
  th.sortable {
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  th.sortable:hover {
    background-color: #f5f5f5;
  }

  .product-table{
    table-layout: fixed;
  }

  .product-table tr td {
    max-width: 100%;
    overflow-x: auto;
    }

  .product-table th, 
.product-table td {
  padding: 10px;
  text-align: left;
  white-space: nowrap; 
  overflow: hidden;
  box-sizing: border-box;
}

.product-table td {
text-overflow: ellipsis;
}

/* PRODUCTS */

  .product-table th:nth-child(1), 
.product-table td:nth-child(1) {
  width: 55%;
}

.product-table th:nth-child(2), 
.product-table td:nth-child(2) {
  width: 10%; 
}

.product-table th:nth-child(3), 
.product-table td:nth-child(3) {
  width: 135px;
}

.product-table th:nth-child(4), 
.product-table td:nth-child(4) {
  width: 75px;
}

.product-table th:nth-child(5), 
.product-table td:nth-child(5) {
  width: 100px;
}

/* COUPONS */

.coupon-table th:nth-child(1), 
.coupon-table td:nth-child(1) {
  width: 40%;
}

.coupon-table th:nth-child(2), 
.coupon-table td:nth-child(2) {
  width: 20%; 
}

.coupon-table th:nth-child(3), 
.coupon-table td:nth-child(3) {
  width: 135px;
}

.coupon-table th:nth-child(4), 
.coupon-table td:nth-child(4) {
  width: 75px;
}

.coupon-table th:nth-child(5), 
.coupon-table td:nth-child(5) {
  width: 100px;
}

/* USERS  */

.user-table th:nth-child(1), 
.user-table td:nth-child(1) {
  width: 20%;
}

.user-table th:nth-child(2), 
.user-table td:nth-child(2) {
  width: 40%; 
}

.user-table th:nth-child(3), 
.user-table td:nth-child(3) {
  width: 20%;
}

.user-table th:nth-child(4), 
.user-table td:nth-child(4) {
  width: 20%;
}


  .product-select-modal-wrapper{
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 900;
    background-color: #0000006e;
  top: 0;
  }

  .product-select-modal{
    padding: 50px;
    border-radius: 5px;
    background-color: #ffffff;
    position: relative;
  }

  .product-select-modal h3{
    margin-bottom: 25px;
  }

  .product-select-modal button{
    padding:5px 10px!important;
    background-color: rgb(85, 116, 110);
    transition:0.15s ease-out;
    color: white;
    border:none;
    border-radius: 5px;
    font-family: nunito;
  }

  .product-select-modal button:hover{
    background-color: rgb(74, 96, 92);
  }

  .product-select-modal button:first-of-type{
    margin-bottom: 25px;
  }

  .product-select-modal button:last-of-type{
    margin-top: 10px;
    position: absolute;
  top: 0;
  right: 10px;
  }

  .product-select-modal .product-item{
    margin: 10px 0;
  border-bottom: 1px solid #e6e1e1;
  padding-bottom: 5px;
  }

  .product-select-modal .product-item input{
    margin-right: 10px;
  }
  
  .coupon-form h3{
    margin: 10px 0 25px;
  }

  .btn-active-status{
    width: 70px;
  }

  .btn-active-status-coupon{
    width: 70px;
  }

  .productOverviewWrapper{
    width: 100%;
  }

  .product-form{
    margin-top: 25px;
  }

  .coupon-form{
    margin-top: 50px;
  }

  .coupon-form h4,
  .product-form h4{
    margin: 25px 0 5px;
  }

  .product-table input{
    border-radius: 5px!important;
    padding: 5px 10px!important;
  }

  .coupon-form  select{
    height: fit-content!important;
    margin-left: 0!important;
  }

  .product-table input,
  .coupon-form input,
  .coupon-form select,
  .coupon-form textarea,
  .containerProducts form input,
  .containerProducts form select, 
  .containerProducts form textarea{
    font-family: nunito!important;
    padding: 10px;
    box-sizing: border-box;
    background-color: #e9e8ee;
    border:none;
    width: 100%;
    box-sizing: border-box;
    transition: 0.15s ease-out;
  }

  .product-table input:hover,
  .containerProducts form input:hover,
  .containerProducts form select:hover, 
  .containerProducts form textarea:hover{
    background-color: #dddbe5;
  }

  .adminProductSplash{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .productPreviewButton{
    position: fixed;
    right: 50px;
    bottom: 50px;
    width: 100px;
    height: 50px;
    border-radius: 5px;
    background-color: rgb(254, 211, 38);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-align: center;
    -webkit-box-shadow: 0px 0px 18px -10px rgba(179,179,179,1);
    -moz-box-shadow: 0px 0px 18px -10px rgba(179,179,179,1);
    box-shadow: 0px 0px 18px -10px rgba(179,179,179,1);
    cursor: pointer;
    transition:0.15s ease-out;
  }

  .productPreviewButton:hover{
    background-color: rgb(215, 180, 40);
  }

  .productPreviewButton i{
    margin-right: 10px;
  }

  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .admin-controls {
      align-items: stretch;
    }
    
    .control-group {
      flex-direction: column;
    }

    .prouduct table td{
        text-align: right!important;
    }
    
    .search-input {
      max-width: none;
    }
    
    .add-product-link {
      width: 100%;
    }
    
    .btn-add-product {
      width: 100%;
    }


    .containerProducts .lightBorder{
      width: 100%;
      box-sizing: border-box;
    }

    .product-table tr{
     border-bottom: 2px solid rgb(85, 116, 110);
     margin-bottom: 12.5px;
     padding-bottom: 12.5px;
    }

    .product-table td{
      width: 100%!important;
      text-wrap: wrap;
  text-align: end;
    }

    .productPreviewButton{
      padding: 10px 20px;
      bottom: 5vw;
      right: 5vw;
    }

    .mobileCouponButtonsWrapper button{
      width: 30%;
    }
  }