@media (max-width: 1200px) {

    #homeBox{
        height: fit-content;
    }

    #homeWrapper{
        height: fit-content;
        position: relative;
    }

    #homeOverlay{
        height: 100%;
    }

    #homeWrapper .highlights{
        flex-direction: column;
        align-items: center;
        height: fit-content;
        margin-top: 50px;
    }

    .highlightsDiv img{
        left: 50%;
  top: 0;
  transform: translateX(-50%);
    }

    #homeWrapper .hightlightsLink{
        width: calc(100% - 50px);
        height: 90vw;
        margin-bottom: 50px;
        max-width: 300px;
    }

    #homeWrapper .hightlightsLink:last-of-type{
        margin-bottom: 0;
    }

    .homeMobileNavBorder{
        border-top: 20px solid rgb(237, 193, 36);
        border-right:  20px solid rgb(237, 193, 36);
        border-left:  20px solid rgb(237, 193, 36)
    }

    .noTransition{
        transition: none!important;
    }

    #homeOverlay{
        border-top: none;
    }

    #splashScreen{
        top:0;
    }

    .btnBox{
        background-color: #fed126;
        height: fit-content;
        flex-direction: column;
    }

    .btnBox a{
        height: fit-content;
    }

    .btnWrapper{
        padding: 0;
    }

    #homeContent button{
        position: relative!important;
        margin-top: 50px;
    }

    .addresses{
        flex-direction: column;
        margin: 50px 0;
    }

    .addresses a{
        margin-bottom: 15px;
        align-self: stretch;
    }

    .addresses a:last-of-type{
        margin-bottom: 0;
    }

    .contactWrapper{
        flex-direction: column;
        margin-bottom: 25px;
    }

    .contactDiv{
        flex-direction: column;
    }

    .contactDiv div{
        margin-right: 0;
    }

.contactDiv a:first-of-type{
    margin-bottom: 5px;
}

    .contactDiv a:last-of-type{
        margin-bottom: 15px;
    }

    

}

@media (min-width: 450px) and (max-width: 1200px) {

#homeWrapper .highlights{
    flex-wrap: wrap;
    flex-direction: row;
}

#homeWrapper .hightlightsLink{
    max-width: 300px;
    max-height: 400px;
}
#homeWrapper .hightlightsLink:last-of-type {
    margin-bottom: 50px;
  }

  .contactSplitter{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contactSplitter .btnWrapper{
    margin-right: 50px;
  }

  .contactSplitter .contactWrapper{
    margin-bottom: 0;
  }


}