#setMenuBox{
        width: 100%;
        min-height: 100vh;  
        display: flex;
  justify-content: center;
}

#setMenuWrapper{
        max-width: 1200px;
        border-radius: 8px;
        color: rgb(47, 47, 47);
        width: 100%;
        box-sizing: border-box;        
}

.activeMenuInfo{
        padding: 25px 0;
        display: flex;
  justify-content: space-between;
  align-items: start;
}

.setMenuWrapper{
  width: fit-content;
  flex-shrink: 1;
  margin-right: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.activeMenuInfo ul{
        list-style: none;
  padding: 0; 
  width: 100%;
}

.activeMenuInfo li{
display: flex;
gap: 20px; 
}

.activeMenuInfo li p{
margin:10px 0;
}


@media (max-width: 1200px) {

        .activeMenuInfo{
            flex-direction: column;
            flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    padding: 0;
        }
    
        .activeMenuInfo div{
            box-sizing: border-box;
            width: 100%;
        }

        .activeMenuInfo embed{
                width: 100%;
                border: 1px solid black;
 
        }

        .currentMenu{
                margin: 50px 0 15px;
        }
    
    }

