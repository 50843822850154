body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

#root{
  overflow-x: hidden;
}

.App{  
  background-color: #0A0A0A;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --jonquil: #fed126ff;
--orange-crayola: #e57a44ff;
--dark-purple: #422040ff;
--bleu-de-france: #008bf8ff;
--fluorescent-cyan: #12eaeaff;
}

.btn-pulci-green{
  background-color: rgb(85, 116, 110);
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.2s ease-out;
}

.btn-pulci-green:hover{
  background-color: rgb(63, 79, 76);
}

.btn-cancel{
  border: none;
  background-color: #FE5F55;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
}

.btn-cancel:hover{
  background-color: #d85147;
}

.btn-back{
  margin: 0 0 25px 0;
}

button:disabled {
  background-color: #ccc; /* Grey background */
  cursor: not-allowed;   /* Not-allowed cursor */
  opacity: 0.6;          /* Slightly transparent */
}

.green{
  color: rgb(85, 116, 110);
}
