.cookie-banner{
    position: fixed;
    bottom: 0;
    z-index: 1000;
    background-color: #0a0a0af6;
    color:white;
    width: calc(100vw - 50px);
    border-radius: 5px;
    margin: 25px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    font-family: merriweather;

}

.cookie-banner a{
    color: inherit;
}

.cookie-banner p{
    margin: 0;
    text-align: center;
}

.cookie-banner div{
margin-top: 25px;
}

.cookie-banner button{
    background-color: rgb(255, 212, 42);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  text-decoration: none;
  color: rgb(47, 47, 47);
  transition: 0.15s ease-out;
}

.cookie-banner button:hover{
    background-color: rgb(201, 168, 34);
}

.cookie-banner button:first-of-type{
    margin-right: 25px;
}


@media (max-width: 750px) {

    .cookie-banner{
        text-align: center;
        
    bottom:25px;
    }


}