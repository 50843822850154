#retourBox {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f3ec;
    font-family: merriweather;
    color: rgb(47, 47, 47);
    padding-bottom: 100px;
    padding-top: 50px;
  }

  .retourWrapper{
    max-width: 800px;   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 calc(5vw + 25px);
  }

  .retourWrapper span{
    text-align: center;
    width: 100%;
    display: inline-block;
  }

  .retourWrapper h1{
    text-align: center!important;
    margin-bottom: 100px;
  }

  .retourWrapper div{
    margin-top: 50px;
    width:100%;
  }

  .retourWrapper .wieWrapper{
    display: flex;
  flex-direction: column;
  align-items: center;

  }

  .retourWrapper a{
    text-decoration: none;
    cursor: pointer;
    transition: 0.15s ease-out;
    color: inherit;
  }

  .retourWrapper a:hover{
    color: black;
  }

  .retourWrapper div:last-of-type{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .retourWrapper img{
    margin: 50px auto;
  height: 50px;
  }

  .retourWrapper .wieWrapper span{
    margin-bottom: 25px;
  }

  .retourWrapper .wieWrapper p strong{
  width: 120px;
  display: inline-block;
  }



  @media (max-width: 750px) {

    .retourWrapper span,
    .retourWrapper li{
        font-size: 0.85rem !important;
        text-align: start;
    }

    .retourWrapper .wieWrapper{
    align-items: start!important;
  
    }

    .retourWrapper .wieWrapper p{      
      font-size: 0.85rem !important;
    }

    .retourWrapper h1{
        margin-bottom: 50px;
      }

      .retourWrapper ul{
        padding-left: 13px;
      }

      .retourWrapper img{
      height: 40px;
      }

      .retourWrapper .closingTitle{
        text-align: center;
      }

  }