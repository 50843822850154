/* Styling the container */

.loaderDiv{
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 400px;
box-sizing: border-box;
  padding-bottom: 70px;
}

.confirmDiv{
  display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 400px;
box-sizing: border-box;
  padding-bottom: 70px;
}

.confirmDiv h4{
  border: none!important;
}

.shipping-options h4{
  text-align: center;
}

#optionsWrapper{
  height: 400px;
  overflow-y: scroll;
}

#tabsBox{
  flex-direction: row!important;
  justify-content: center;
}

.activeTab{
  font-weight: bold;
}

#tabsBox span{
  cursor:pointer;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  margin: 0 10px 20px 0;
  width: 120px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

#tabsBox img{
  margin:0;
  height: 15px;
  margin-left: 5px;
}

#deliveryTab div{
  padding: 10px 0;
}

#deliveryTab div p:first-of-type{
  margin: 0 0 20px 0!important;
}
#deliveryTab div p:last-of-type{
  margin: 0!important;
}

.shipping-options {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
    font-family: 'Garamond', serif;
    margin-bottom: 25px;
  }

  .shipping-options img{
    height: 50px;
  margin: 0 auto 20px;
  display: block;
  }
  
  /* Styling the list of shipping options */
  .shipping-options ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  /* Styling individual options */
  .shipping-options li {
    border: 1px solid #ddd;
    background-color: #fff;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  /* .shipping-options li:hover {
    background-color: #f1f1f1;
  } */
  
  /* Styling the radio buttons */
  .shipping-options input[type="radio"] {
    margin-right: 25px;
    cursor:pointer;
  }
  
  /* Styling the labels */
  .shipping-options label {
    display: flex;
  }
  
  /* Styling the strong text */
  .shipping-options strong {
    font-weight: bold;
    color: #343a40;
  }
  
  /* Styling additional information */
  .shipping-options div {
    color: #495057;
    display: flex;
    flex-direction: column;
  }
  
  /* Styling the continue button */
  .shipping-options button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    width: 100%;
  }
  
  .shipping-options button:hover {
    background-color: #0056b3;
  }
  
  /* Grouping delivery and pickup options */
.shipping-method-group {
  margin-bottom: 20px;
}

/* Headings for each group */
.shipping-method-group h3 {
  font-size: 18px;
  color: #007bff;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.openingWrapper{
 flex-direction: row!important;
 align-items: center;
 cursor:pointer;
}

.openingTimes li{
  padding: 5px 0;
  border: none;
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  margin:0;
}


.openingWrapper p:last-of-type{
  margin:5px 0 5px 10px!important;
}

.pickupWrapper p{
margin: 5px 0!important;
}

.pickupWrapper a{
  margin: 5px 0 5px 0!important;
  color:rgb(85, 116, 110)!important;
}

.pickupWrapper{
  flex-direction: row!important;
}

.openingWrapper h4{
  margin: 10px 0;
}



@media (max-width: 450px) {

  #deliveryTab div p:first-of-type {
    margin: 0 0 5px 0!important;
  }

}



