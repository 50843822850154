#adminSideBarBox{
    width: 20vw;
    min-width: 300px;
    max-width: 400px;
    position: absolute;
    height: 100%;
    left: 0;
    z-index: 10;
    background-color: #f9f9fb;
    padding: 50px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    font-family: nunito;
}

#adminSideBarSearch{
    width: 100%;
    margin-top: 30px;
    position: relative;
}

#adminSideBarSearch i {
    position: absolute;
    left: 20px; 
    top: 50%;
    transform: translateY(-50%);
    color: rgb(85, 116, 110);
    font-size: 16px;
}

#adminSideBarSearch input{
    border-radius: 50px;
    padding: 15px 0 15px 45px;
    box-sizing: border-box;
    background-color: #e9e8ee;
    border:none;
    width: 100%;
    transition: 0.15s ease-out;
}
#adminSideBarSearch input:hover{
    background-color: #dddbe5;
}


#adminSideBarLinks{
    width: 100%;
}

#adminSideBarBox img{
height: 50px;
margin-left: 20px;
}

#adminSideBarBox ul{
    padding:0;
    width: 100%;
}

#adminSideBarBox ul h3{
    font-weight: 500;
    color: #565555;
    margin: 30px 0 10px 20px;
    font-size: 1.1rem;
}

#adminSideBarBox ul li{
    list-style: none;
    padding: 0 5px;
    margin:10px 0;
    width: 100%;
    padding:15px 0 15px 20px; 
    box-sizing: border-box;
    transition: 0.15s ease-out;
    border-radius: 50px;    
    position: relative;
    cursor: pointer;
}

#adminSideBarBox ul li:hover{
    background-color: #e9e8ee;
}

#adminSideBarBox ul .activeSideBarItem{
    background-color: rgb(85, 116, 110);
    color: white;
}

#adminSideBarBox ul .activeSideBarItem:hover{
    background-color: rgb(74, 96, 92);
}

#adminSideBarBox ul .activeSideBarItem a{
color: white;
}

#adminSideBarBox ul li i{
margin-right: 10px;
}

#adminSideBarBox ul li a{
text-decoration: none;
color:black;
}

#adminSideBarBox ul li .order-count {
    top: 0px;
    right: 0;
  }

  .btn-download.orange,
  .btn-download.orange a,
  .btn-download.orange i{
    background-color: #e57a44ff!important;  
    color: white!important;
    transition: 0.15s ease-out;
  }

  .btn-download.orange:hover,
  .btn-download.orange:hover a,
  .btn-download.orange:hover i{
    background-color: rgb(176, 89, 45)!important;     
    transition: 0.15s ease-out;
  }
  
  .btn-download.red,
  .btn-download.red a,
  .btn-download.red i {
    background-color: #FE5F55!important; 
    color: white!important;
  }

  .btn-download.red:hover,
  .btn-download.red:hover a,
  .btn-download.red:hover i {
    background-color: #d05d55!important; 
  }

  
    
  .order-count {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: red;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  