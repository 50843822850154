h1{
  text-align: start!important;
}

.desktopNavSpacer{
  background: #2F2F2F;
  height: calc(20vh + 20px);
  width: 100%;  
  min-height: 205px;
  box-sizing: border-box;
}

#homeBox{

    min-height: calc(100vh + 20px + 20vh);    
    background-color: #0A0A0A;
    color:#F6F8FF;
    font-family: merriweather;
    text-align: justify;
    position:relative;
}

.gradientSpacer{
    height: 150px;
    width: 100%;
}

.gradientSpacer img{
    height: 100%;
    width:100%;
}

.background-image {
    position: absolute;
    width: 50%;
    max-width: 800px; /* Set your max-width here */
    height: calc(100% - 20vh - 20px);
    bottom: 0;
    left: 0;   
    object-fit: cover; /* Similar effect to background-size: cover */
    z-index: 0;
  }



.navWrapper{
height: calc(20vh + 20px);
width: 100%;
background-color: #2F2F2F;
min-height: 205px;
box-sizing: border-box;
}


.splitterBox{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.infoBox{

    width: 50%;
    padding: 50px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: 100vh;
    box-sizing: border-box;

}

.infoWrapper{
    background-color: rgba(0, 0, 0, 0.85);
    padding:50px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    max-width: 40vw;

}

.buttonsBox{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;    
    margin-top: 50px;
}

.buttonsBox button{
    color:#2F2F2F;
    font-family: merriweather;
    width: 175px;    
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  transition: 0.15s ease-out;
}

.buttonsBox a{
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonsBox .instaLogo{
  height:40px;
}

#homeBox .buttonsBox button:hover,
#liquorBox button:hover,
#storeBox button:hover
{
    background-color: rgb(214, 173, 7);
}

.buttonsBox a {    
  text-decoration: none;
}

.buttonsBox button:first-of-type{

    margin-right: 10px;
}

.imageBox{
    height: fit-content;
  width: 50%;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.imageBox div{
    width: 100%;
    height: fit-content;
}

.yellow{
    color:rgb(255, 212, 41);
  }
  

  #satiataBox{
    background-color: #e9e2d0;
    overflow: hidden;
    position: relative;    
  font-family: merriweather;
  padding: 100px 0 50px;
  }

  #satiataBox h1{
    margin: 0 0 25px;
    color:#04030F;
  }

  .satiataLogoWrapper{
    height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  }

  .satiataLogoWrapper img{
    height: 100px;
    margin: 0!important;
  }

  #satiataBox .infoWrapper{
    background-color: transparent!important;
  }

  #satiataBox p{
    color: #04030F;
    text-align: justify;
  }

  #satiataBox button,
  #storeBox button{
    background-color: #811520;
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  }

  .mapsButton img{
    height:25px;
    margin-right: 10px;
  }

  #satiataBox a{
    text-decoration: none;
  }

  #satiataBox button:hover,
  #storeBox button:hover{
    background-color: #551319;
  }

  #satiataBox .splitterBox{
    flex-direction: column-reverse;
  }

  #satiataBox .infoBox{
    height: fit-content!important;
    width: 100%;
    flex-direction: row;
    align-items: center;
    max-width: 1300px;
    margin: 0 auto 100px;
    position: relative;

  }

  .logoImageSatiata{
    height: 100px;    
  }

  .infoImageWrapper{
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  margin-right: 100px;
  }

  .octoImage{
    max-width: 400px;
    position: relative;
    z-index: 5;
  }

  .imageBoxSatiata{
    width: 100vw;
    height: 400px;    
    margin-bottom: 100px;
  }

  .imageBoxSatiata img{
    height: 400px!important;
  }

  #satiataBox #sliderBox{
    height: 400px;    
  }





#storeBox{
  min-height: 100vh;
  /* background-color: #0A0A0A; */
  background-color: #e9e2d0;
  font-family: merriweather;
  /* color: #F6F8FF; */
  color: #04030F;
  padding: 0 0 100px;
  box-sizing: border-box;

}



.satiataRed{
color:#811520;
}

#storeBox .slick-slider{
  margin-top:100px;
}

#storeBox .slick-slide img {
  height: 400px !important;
}

#storeBox #sliderBox{
  height: 400px;
}

#storeBoxSplitter{

  text-align: justify;  
  display: grid;
  grid-template-columns: 1fr auto; 
  align-items: center;
}


#storeBoxImage,
#storeBoxInfo{
  height: 100vh;
}

#storeBoxImage{
  position: relative;
  grid-column: 2; /* Place in the right column */
  justify-self: end;
}

#storeBoxImage .overlay-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom, 
    rgba(233, 226, 208, 1) 0%,   /* Fully visible at the top */
    rgba(233, 226, 208, 0) 30%,  /* Fades out towards the middle */
    rgba(233, 226, 208, 0) 70%,  /* Fades in again from the middle */
    rgba(233, 226, 208, 1) 100%  /* Fully visible at the bottom */
  );
  pointer-events: none; /* Ensure the overlay doesn't block interaction */
}

#storeBoxImage img{
  height: 100%;  
}

.storeBoxLogoWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px 0 50px;
}

.textlogo{
  position: absolute;
  z-index: 1;
  height: 300px;
  margin: auto;
  right: 0;
  left: 0;
}

#storeBoxBottomText p{
  position: relative;
  z-index: 5;
}


#storeBoxInfo{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 100px;
  grid-column: 1; 
}

#storeBoxInfo h1{
  margin: 0 0 50px;
}

#storeBoxBottomText{
  display: flex;
  max-width: 1200px;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  margin: 0 auto;
}

.kunstRoofLogo{
  height: 300px;
  margin-right: 100px;
}

#liquorBox{
    min-height: 100vh;    
    background-color: #0A0A0A;
    font-family: merriweather;
    color:#F6F8FF;
    padding:100px 0;
    box-sizing: border-box;
     text-align: justify;
}

#liquorBox strong{
        color:#ffd429;
}

#liquorBoxLogoWrapper{
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;  
  margin-bottom: 100px;
  padding: 0 100px;
}

#ourBox{
  height: 200px;
  overflow: hidden;
  position: relative;  
  margin: 150px 0;
  display: flex;
}

#ourBox img{
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
}

#liquorBoxLogoWrapper img{
  height: 100%;
}

#liquorBoxTextWrapper{
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 100px;
}

#liquorBox h1{
    margin: 0 0 50px;
}

#liquorBox h2{
  position: relative;
  z-index: 3;
    text-align: center;
    height: 100%;
    display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 25px 50px;
  margin: auto;
  background: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 5px;
  color: #F6F8FF;
  font-weight: bold;
}

.liquorShowBox{
  padding: 0 100px;
}

#liquorBox h4{
margin: 200px 0 50px;
text-align: center;
}

#liquorBox h2 img{
margin-right: 25px;
height: 100%;
}

.liqourImageWrapper{
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.liqourImageWrapper img{
    height: 100%;
}

.liquorShowElement{
    height: 600px;
    display: flex;
    justify-content: center;    
    margin-bottom: 50px;
}

.liqourButtonWrapper{
    display: flex;
    margin-top: 50px;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  flex-direction: row !important;
}

.liqourButtonWrapper button{
width: 150px;
}

.liqourButtonWrapper button:first-of-type{
    margin-right: 10px;
    }

.reverseElement{
    flex-direction: row-reverse;
}

.liquorShowElement div{
    width: 50%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
}

.liquorShowElement img{
    height: 100%;
    object-fit: contain; 
}

.craftmanWrapper{
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}

.craftmanOverlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient( to bottom, rgba(10, 10, 10, 1) 0%, rgba(233, 226, 208, 0) 25%, rgba(233, 226, 208, 0) 75%, rgba(10, 10, 10, 1) 100% );
  pointer-events: none;
  z-index: 2;
  color:rgb(10, 10, 10);
}

.craftmanTextWrapper{
  position: relative;
  font-family: merriweather;
  max-width: 800px;
  z-index: 5;
  margin: 150px 0;
  background: rgba(6, 6, 6, 0.52);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 50px;
  color: #F6F8FF;

}

.craftmanWrapper h4{
  margin: 0 0 50px!important;
  text-align: center;  
  font-size: 1.4rem;
}

.craftmanWrapper p{
  text-align: justify;
}

.craftmanWrapper img{
  position: absolute;
  width: 100%;
    height: auto;
  z-index: 1;
  top:0;
  bottom: 0;
  margin: auto;
}

#craftmanLogoWrapper{
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 0;
}

#craftmanLogoWrapper img{
  height: 100%;
  width: auto;
  position: relative!important;
}



#aboutBox{
  min-height: 100vh;    
    background-color: #0A0A0A;
    font-family: merriweather;
    color:#F6F8FF;
    padding:100px 100px 200px;
    box-sizing: border-box;    
    text-align: justify;
    display: flex;
    align-items: center;
    flex-direction: column;

}

#aboutBox h1{
  margin: 0 0 100px;   
  text-align: center!important;
}

#aboutBox h2{
  margin: 0 0 25px;
}

#aboutSplitter{
  display: flex;
  justify-content: center;
}

#aboutImageSlider{
  margin-right: 100px;
  display: flex;
  align-items: center;
  width: 50%;
  max-width: 600px;
}

#aboutImageSlider img{
  max-height: 800px;
  width: auto;
  margin: auto;
  max-width: 100%;
}

#aboutInfo{
  max-width: 700px;
  width: 50%;
}

#aboutBottomText{
  margin: 100px 0;
  max-width: 800px;
}


#contactBox{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f3ec;
  font-family: merriweather;
  color: rgb(47, 47, 47);
  padding-bottom: 100px;
  padding-top: 50px;
}

#contactBox h1{
  margin: 50px 0 100px;
}

#contactSplitter{
  display: flex;
  max-width: 1300px;
}

#contactTitleWrapper{
  max-width: 1300px;
}

#contactInfoWrapper{
  margin-right: 100px;
}

#contactInfoWrapper a{
  color:#55746E;
  cursor: pointer;
}

#contactInfoWrapper a:visited{
  color:#55746E;
}


#addressWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  align-items: center;
  width: 100%;
  margin: 50px 0;
}

.addressCol{
  max-width: 33%;
  min-width: 250px;
}


.mapsSplitter{
  display: flex;
}

.mapsSplitter img{
  height: 30px;
  margin-right: 10px;
}

.mapsSplitter div{
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}

#addressWrapper p {
  margin: 0;
  display: flex;
  align-items: center;
  transition: 0.15s ease-out;
}

#addressWrapper p img {
  margin-right: 10px;
}
#addressWrapper a {
  text-decoration: none;
}

#addressWrapper a p{
  color:#2F2F2F!important;
  font-size: 0.9rem;  
  margin-bottom: 5px;
}

#addressWrapper a p:hover {
  color: black !important;
}

#addressWrapper h3 {
  margin: 0;
}

#openingWrapper {
  display: grid;
  grid-template-columns: 20px 1fr; /* Two equal-width columns */
  gap: 20px;
}
