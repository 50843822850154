@media (max-width: 1200px) {
    #detailsBox {
        margin: 0;
        padding: 50px 5vw;
    }
}


@media (max-width: 950px) {


    #detailsBox {
        flex-direction: column;
    }

    .detailsColumn{
        width: 100%;        
    }

    .detailsColumn:first-of-type{
        margin-bottom: 50px;
    }

    .detailsColumn:last-of-type{
        margin-bottom: 50px;
        background-color: #2F2F2F69;
        border-radius: 5px;        
        padding: 5vw;        
        box-sizing: border-box;
        max-width: 700px;
    margin: auto;
    }

    .priceDetails p{
        display: block!important;
        text-align: center;
    }

    .coupon-input-wrapper {
        width: 100%;
      }

}