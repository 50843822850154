/* src/components/styling/adminOrderDetails.css */
#adminOrderDetailsWrapper{
  width: 100%;
  min-height: 100vh;  
  display: flex;
  justify-content: center;
}

.detailsContainer {
  width: 100%;
}

.adminButtonsDiv{
  display: flex;
  padding:0 0 20px 0;
  align-items: center;
}

.adminButtonsDiv button{
  height: 100%;
  max-height: 40px;
}

.adminButtonsDiv p{
  margin-left: 10px;
}


.detailsContainer button{
  margin-left: 20px;
}

.detailsContainer h1{
  margin: 0 0 50px 18px;
  border-bottom: 2px solid rgb(85, 116, 110);
  padding-bottom: 10px;
}

.details-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 2fr));
  justify-items: stretch;
  gap: 50px 20px;  
  margin-bottom: 20px;
  width: 100%;
}

.details-section .lightBorder{
  box-sizing: border-box;
  min-width: 350px;
  max-width: 100%;
}

.details-list,
.details-lines-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.details-list{  
 width: 100%;
}

.details-list li
 {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  align-items: center;
}

.details-list li .btn-pulci-green{
  padding:5px 10px;
  background-color: rgb(85, 116, 110);
  color:white;
  font-family: nunito;
  height: 25px;
  box-sizing: border-box;
}

.details-lines-list{
max-width: 600px;
}

.details-lines-list h3{
  margin: 0;
  }

.details-lines-list li {
  display: grid;
  grid-template-columns: 400px 75px;
  gap: 20px; 
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.details-lines-list li:last-of-type {
  border-bottom: none;
  border-top: 1px solid #ddd;
}

.details-list li span.label,
.details-lines-list li span.label  {
  font-weight: bold;
}

.statusValue{
  display: flex;
  align-items: center;
}

.save-icon {
  cursor: pointer;
  margin-right: 10px;
}

.check-icon{
  cursor: default!important;
}

.lightBorder select{
  flex-shrink: 0; 
  flex-grow: 0; 
  border:none;
  border-radius: 5px;
  margin-left: 10px;
  background-color: #e9e8ee;
  font-family: nunito;
  padding: 2px 10px;
  height: 25px;
  box-sizing: border-box;
  transition:0.15s ease-out;
}

.lightBorder select:hover{
  background-color: #dddbe5;
}

#noteBox{
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  min-width: 350px;
  box-sizing: border-box;
}

.detailsContainer h2 {
  margin-bottom: 50px;
}

.loading {
  text-align: center;
  font-size: 18px;
  color: #333;
}


/* Responsive adjustments */
@media (max-width: 1200px) {

  .detailsContainer {
    padding: 20px;
    width: 100%;
  }

  .adminButtonsDiv {
    flex-direction: column;
    gap: 10px;
  }

  .adminButtonsDiv button {
    width: 100%; /* Full-width buttons on mobile */
    max-height: unset; /* Remove height restriction */
    margin-bottom: 10px;
  }


  .adminButtonsDiv p{
    margin-left: 0;
  }

  .adminButtonsDiv div button{
    height: auto;
    padding: 10px 0;
  }

  .details-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .details-section .lightBorder{
    min-width: inherit!important;
  }

  .details-section .lightBorder button{
    height: auto;
  }

  .details-section .lightBorder button,
  .details-section .lightBorder select{
    margin-left: 0!important;
  }

  .details-section .lightBorder:first-of-type{
    margin-top: 25px;
  }

  .details-list, .details-lines-list {
    min-width: 100%;
  }

  .details-list li, .details-lines-list li {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0;
  }

  .details-list li span.label, .details-lines-list li span.label {
    font-weight: bold;
    width: 100%;
  }

  /* Adjust font and spacing for headers */
  .detailsContainer h1 {
    font-size: 1.5em;
    margin-bottom: 20px;
    margin-left: 0;
  }

  .detailsContainer h2 {
    font-size: 1.2em;
    margin: 20px 0 10px;
  }

  /* Note box styling on mobile */
  #noteBox {
    padding: 10px;
    font-size: 0.9em;
    margin-top: 20px;
  }
}
